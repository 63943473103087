import React, { useState } from "react"
import axios from "axios"

import contactStyles from '../styles/modules/contact.module.scss';

const MyForm = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")
    const [serverState, setServerState] = useState({
        submitting: false,
        status: null,
    })

    const handleServerResponse = (ok, msg, form) => {
        setServerState({
            submitting: false,
            status: { ok, msg },
        })

        if (ok) {
            form.reset()
        }
    }
    const handleOnSubmit = e => {
        e.preventDefault()

        const form = e.target

        setServerState({ submitting: true })
        axios({
            method: "post",
            url: "https://api.netrumo.com/contact",
            data: { name: name, email: email, message: message },
        })
            .then(r => {
                handleServerResponse(true, "Thank you for your email!", form)
            })
            .catch(r => {
                handleServerResponse(false, r.response.data.error, form)
            })
    }

    return (
        <div>
            <div className={contactStyles.contactForm}>
                <div className="row">
                <div className="col-6">
                        <h3>Netrumo.com</h3>
                        <p>For advertising, questions or feedback, use the form to reach out to us.</p>
                    </div>
                    <div className="col-6">
                        <h3>Contact Us</h3>
                        <form onSubmit={handleOnSubmit}>
                            <div className={contactStyles.formGroup}>
                                <label>Name</label>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Enter your name"
                                    required="required"
                                    value={name}
                                    onChange={event => setName(event.target.value)}
                                />
                            </div>
                            <div className={contactStyles.formGroup}>
                                <label required="required">Email address</label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={email}
                                    onChange={event => setEmail(event.target.value)}
                                />
                            </div>
                            <div className={contactStyles.formGroup}>
                                <label>Message</label>
                                <textarea
                                    name="message"
                                    value={message}
                                    onChange={event => setMessage(event.target.value)}
                                ></textarea>
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={serverState.submitting}
                            >
                                Submit
                            </button>
                            {serverState.status && (
                                <p className={!serverState.status.ok ? "errorMsg" : ""}>
                                    {serverState.status.msg}
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyForm
